import React, { useState } from 'react';
import { useAuth } from '../authContext';
import { useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';

function Sidebar({ onAction }) {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingAction, setProcessingAction] = useState(null);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleAction = async (action) => {
    if (isProcessing) return;

    setProcessingAction(action);
    setIsProcessing(true);

    try {
      // Handle navigation actions separately
      if (action === 'settings') {
        navigate('/settings');
        setIsProcessing(false);
        setProcessingAction(null);
        return;
      }

      if (action === 'manageUsers') {
        navigate('/manage-users');
        setIsProcessing(false);
        setProcessingAction(null);
        return;
      }

      // For other actions, use the onAction prop
      if (onAction) {
        const result = await onAction(action);
        if (result === false) {
          setIsProcessing(false);
          setProcessingAction(null);
        }
        if (result === true) {
          setIsProcessing(false);
          setProcessingAction(null);
        }
      }
    } catch (error) {
      console.error(`Error executing ${action}:`, error);
      setIsProcessing(false);
      setProcessingAction(null);
    }
  };

  const getButtonText = (action, displayText) => {
    if (processingAction === action) {
      switch (action) {
        case 'sendToAR':
          return 'Sending to AR...';
        case 'sendWeeklySchedule':
          return 'Sending Schedule...';
        default:
          return 'Processing...';
      }
    }
    return displayText;
  };

  const getButtonClass = (baseClass) => {
    return `${baseClass} ${isProcessing ? 'processing' : ''}`;
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-content">
        {/* Core Actions */}
        <button 
          onClick={() => handleAction('newProject')}
          disabled={isProcessing}
          className={getButtonClass('action-button')}
        >
          {getButtonText('newProject', 'New Project')}
        </button>
        
        {/* Admin-only Actions */}
        {user?.isAdmin && (
          <>
            <button 
              onClick={() => handleAction('settings')}
              disabled={isProcessing}
              className={getButtonClass('action-button')}
            >
              Settings
            </button>
            <button 
              onClick={() => handleAction('manageUsers')}
              disabled={isProcessing}
              className={getButtonClass('action-button')}
            >
              Manage Users
            </button>
          </>
        )}

        {/* Logout Button */}
        <button 
          onClick={handleLogout}
          disabled={isProcessing} 
          className={getButtonClass('logout-button')}
        >
          Logout
        </button>

        {/* Spacer */}
        <div className="sidebar-spacer"></div>

        {/* Schedule Actions */}
        <button 
          onClick={() => handleAction('sendToAR')}
          disabled={isProcessing}
          className={getButtonClass('send-to-ar')}
        >
          {getButtonText('sendToAR', 'Send to AR')}
          {processingAction === 'sendToAR' && (
            <div className="button-spinner"></div>
          )}
        </button>

        <button 
          onClick={() => handleAction('sendWeeklySchedule')}
          disabled={isProcessing}
          className={getButtonClass('send-weekly-schedule')}
        >
          {getButtonText('sendWeeklySchedule', 'Send Weekly Schedule')}
          {processingAction === 'sendWeeklySchedule' && (
            <div className="button-spinner"></div>
          )}
        </button>
      </div>

      {/* Processing Overlay */}
      {isProcessing && (
        <div className="processing-overlay">
          <div className="loading-spinner"></div>
          <p>
            {processingAction === 'sendToAR' && 'Sending to AR...'}
            {processingAction === 'sendWeeklySchedule' && 'Sending Weekly Schedule...'}
            {!processingAction && 'Processing...'}
          </p>
        </div>
      )}
    </aside>
  );
}

export default Sidebar;