import { useState, useEffect, useCallback } from 'react';
import apiService from '../services/api.service';

export const useEmailTracking = (projectId) => {
  const [emailStatus, setEmailStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchEmailStatus = useCallback(async () => {
    if (!projectId) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await apiService.getEmailDeliveryStatus(projectId);
      setEmailStatus(response.data);
    } catch (err) {
      console.error('Error fetching email status:', err);
      setError('Failed to fetch email delivery status');
    } finally {
      setIsLoading(false);
    }
  }, [projectId]);

  // Initial fetch
  useEffect(() => {
    if (projectId) {
      fetchEmailStatus();
    }
  }, [projectId, fetchEmailStatus]);

  // Set up polling for status updates
  useEffect(() => {
    if (!projectId) return;

    const pollInterval = setInterval(() => {
      fetchEmailStatus();
    }, 30000); // Poll every 30 seconds

    return () => clearInterval(pollInterval);
  }, [projectId, fetchEmailStatus]);

  const refreshStatus = () => {
    fetchEmailStatus();
  };

  return {
    emailStatus,
    isLoading,
    error,
    refreshStatus
  };
};

export default useEmailTracking;