import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/api.service';
import Header from './Header';
import Sidebar from './Sidebar';
import Calendar from './Calendar';
import ProjectForm from './ProjectForm';
import '../styles/Dashboard.css';
import { useBranch } from '../BranchContext';
import { useAuth } from '../authContext';

function Dashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { 
    selectedBranch, 
    setSelectedBranch, 
    selectedSubBranch, 
    setSelectedSubBranch, 
    branches, 
    refreshBranches 
  } = useBranch();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('month');
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [projectFormDate, setProjectFormDate] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [savingProjectId, setSavingProjectId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // Initial setup and branch refresh
  useEffect(() => {
    console.log('Initial setup - refreshing branches');
    refreshBranches();
  }, []);

  // Branch data fetching
  useEffect(() => {
    if (selectedBranch) {
      console.log('Selected branch changed - fetching branch data');
      fetchBranchData();
    }
  }, [selectedBranch, selectedSubBranch]);

  // Enhanced AR Review update handling
  useEffect(() => {
    let broadcastChannel;
    let lastStorageUpdate = localStorage.getItem('ar-review-completed');

    console.log('Setting up AR Review event listeners');

    const handleARReviewComplete = () => {
      console.log('AR Review completed - Window Event triggered');
      refreshBranchData();
    };
    window.addEventListener('ar-review-completed', handleARReviewComplete);

    const handleStorage = (e) => {
      if (e.key === 'ar-review-completed' && e.newValue !== lastStorageUpdate) {
        console.log('AR Review completed - LocalStorage update detected');
        lastStorageUpdate = e.newValue;
        refreshBranchData();
      }
    };
    window.addEventListener('storage', handleStorage);

    try {
      broadcastChannel = new BroadcastChannel('ar-review-channel');
      broadcastChannel.onmessage = (event) => {
        if (event.data.type === 'ar-review-completed') {
          console.log('AR Review completed - BroadcastChannel message received');
          refreshBranchData();
        }
      };
    } catch (error) {
      console.warn('BroadcastChannel not supported:', error);
    }

    return () => {
      console.log('Cleaning up AR Review event listeners');
      window.removeEventListener('ar-review-completed', handleARReviewComplete);
      window.removeEventListener('storage', handleStorage);
      if (broadcastChannel) {
        broadcastChannel.close();
      }
    };
  }, []);

  const refreshBranchData = async () => {
    console.log('Refreshing branch data...');
    try {
      await fetchBranchData();
      console.log('Branch data refreshed successfully');
    } catch (error) {
      console.error('Error refreshing branch data:', error);
      setError('Failed to refresh data. Please try again.');
    }
  };

  const fetchBranchData = async () => {
    console.log('Fetching branch data...');
    setLoading(true);
    setError(null);
    try {
      const branchId = selectedSubBranch ? selectedSubBranch.id : selectedBranch.id;
      console.log('Fetching data for branch ID:', branchId);

      const [projectsRes, techniciansRes, contractorsRes] = await Promise.all([
        apiService.getProjects(branchId),
        apiService.getTechnicians(branchId),
        apiService.getContractor(branchId)
      ]);

      setProjects(projectsRes.data);
      setTechnicians(techniciansRes.data);
      setContractors(contractorsRes.data);
      console.log('Branch data fetch completed successfully');
    } catch (error) {
      console.error('Error fetching branch data:', error);
      setError('Failed to load branch data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddProject = (date) => {
    setProjectFormDate(date);
    setShowProjectForm(true);
  };

  const closeProjectForm = () => {
    if (editingProject) {
      setEditingProject(null);
    } else {
      setShowProjectForm(false);
    }
    setSavingProjectId(null);
  };

  const handleSaveProject = async (project) => {
    console.log('handleSaveProject called with:', project);
    const projectId = project.id;

    // Check if this specific project is already being saved
    if (savingProjectId === projectId) {
      console.log('Save already in progress for project:', projectId);
      return false;
    }

    if (isSaving) {
      console.log('Save already in progress, skipping...');
      return false;
    }

    setIsSaving(true);
    setSavingProjectId(projectId);

    try {
      const projectWithBranch = { 
        ...project, 
        branchId: selectedSubBranch ? selectedSubBranch.id : selectedBranch.id 
      };

      let response;
      if (projectId) {
        console.log('Updating existing project:', projectId);
        response = await apiService.updateProject(projectId, projectWithBranch);
      } else {
        console.log('Creating new project');
        response = await apiService.createProject(projectWithBranch);
      }

      if (!response || !response.data) {
        console.error('Invalid response from server:', response);
        return false;
      }

      // Update local state
      setProjects(prev => {
        const newProjects = projectId ? 
          prev.map(p => p.id === response.data.id ? response.data : p) : 
          [...prev, response.data];
        return newProjects;
      });

      // Close form in next tick to avoid state race conditions
      setTimeout(() => {
        closeProjectForm();
      }, 0);

      return true;
    } catch (error) {
      console.error('Error in handleSaveProject:', error);
      if (error.response) {
        console.error('Server error response:', error.response.data);
      }
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteProject = async (projectId) => {
    console.log('Deleting project:', projectId);
    try {
      await apiService.deleteProject(projectId);
      setProjects(projects.filter(p => p.id !== projectId));
      closeProjectForm();
      await refreshBranchData();
      return true;
    } catch (error) {
      console.error('Error deleting project:', error);
      alert('Failed to delete project. Please try again.');
      return false;
    }
  };

  const handleSendARSchedule = async () => {
    if (!selectedBranch) {
      setError('Please select a branch first.');
      return false;
    }

    console.log('Sending AR Schedule...');
    try {
      const branchId = selectedBranch.id;
      const subBranchId = selectedSubBranch ? selectedSubBranch.id : null;

      // Check AR settings
      const targetBranchId = subBranchId || branchId;
      const branchSettings = await apiService.getEmailSettings(targetBranchId);

      if (!branchSettings.data?.arEnabled) {
        alert('AR integration is not enabled for this branch. Please enable it in Email Settings first.');
        return false;
      }

      if (!branchSettings.data?.arRecipients) {
        alert('No AR recipients configured. Please add AR recipients in Email Settings first.');
        return false;
      }

      await apiService.sendARSchedule(branchId, subBranchId);
      alert('AR review request created successfully!');
      return true;
    } catch (error) {
      console.error('Error sending AR schedule:', error);
      alert('Failed to create AR review request. Please try again.');
      return false;
    }
  };

  const handleSendWeeklySchedule = async () => {
    if (!selectedBranch) {
      setError('Please select a branch first.');
      return false;
    }

    console.log('Sending weekly schedule...');
    try {
      await apiService.sendWeeklySchedule(
        selectedBranch.id, 
        selectedSubBranch ? selectedSubBranch.id : null
      );
      alert('Weekly schedule sent successfully!');
      return true;
    } catch (error) {
      console.error('Error sending weekly schedule:', error);
      alert('Failed to send weekly schedule. Please try again.');
      return false;
    }
  };

  const handleSidebarAction = async (action) => {
    console.log('Handling sidebar action:', action);
    switch(action) {
      case 'newProject':
        setProjectFormDate(new Date());
        setShowProjectForm(true);
        return true;
      case 'settings':
        navigate('/settings');
        return true;
      case 'manageUsers':
        navigate('/manage-users');
        return true;
      case 'sendWeeklySchedule':
        return handleSendWeeklySchedule();
      case 'sendToAR':
        return handleSendARSchedule();
      default:
        console.log('Unhandled action:', action);
        return false;
    }
  };

  return (
    <div className="dashboard">
      <Header 
        selectedDate={selectedDate}
        onDateChange={setSelectedDate}
        viewMode={viewMode}
        onViewModeChange={setViewMode}
      />
      <div className="dashboard-content">
        <Sidebar 
          onAction={handleSidebarAction} 
          refreshData={refreshBranchData} 
        />
        <main className="main-content">
          {loading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="error-container">
              <div className="error-message">{error}</div>
              <button onClick={refreshBranchData} className="retry-button">
                Retry
              </button>
            </div>
          ) : (
            <div className="calendar-container">
              <Calendar 
                selectedBranch={selectedSubBranch || selectedBranch}
                selectedDate={selectedDate}
                viewMode={viewMode}
                onAddProject={handleAddProject}
                onUpdateProject={setEditingProject}
                onDeleteProject={handleDeleteProject}
                projects={projects}
                technicians={technicians}
                contractors={contractors}
                refreshData={refreshBranchData}
              />
            </div>
          )}
        </main>
      </div>

      {(showProjectForm || editingProject) && (
        <ProjectForm
          key={editingProject ? `edit-${editingProject.id}` : 'new-project'}
          project={editingProject}
          onClose={closeProjectForm}
          onSave={handleSaveProject}
          onDelete={handleDeleteProject}
          selectedDate={editingProject ? new Date(editingProject.dateTime) : projectFormDate}
          selectedBranch={selectedBranch}
          selectedSubBranch={selectedSubBranch}
          technicians={technicians}
          contractors={contractors}
          isSaving={isSaving || !!savingProjectId}
        />
      )}
    </div>
  );
}

export default Dashboard;