import React from 'react';
import '../styles/ARSuccess.css';

export default function ARReviewSuccess() {
  const handleClose = () => {
    // Try window.close() first
    const closed = window.close();
    
    // If window.close() didn't work (returns undefined and window still exists)
    if (closed === undefined && window.location) {
      // Fallback to redirecting to blank page
      window.location.href = "about:blank";
    }
  };

  return (
    <div className="ar-success-container">
      <div className="ar-success-card">
        <div className="ar-success-icon">
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        
        <h2 className="ar-success-title">
          Review Submitted Successfully
        </h2>
        
        <p className="ar-success-message">
          Your AR review has been processed and the project scheduler has been updated.
          Thank you for your submission.
        </p>

        <button
          onClick={handleClose}
          className="ar-close-button"
        >
          Close Window
        </button>
      </div>
    </div>
  );
}