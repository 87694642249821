import React, { useState } from 'react';

export const Tooltip = ({ 
  children, 
  content, 
  className = '', 
  position = 'top',
  delay = 200 
}) => {
  const [show, setShow] = useState(false);
  let timeout;

  const handleMouseEnter = () => {
    timeout = setTimeout(() => {
      setShow(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeout);
    setShow(false);
  };

  const getPositionClasses = () => {
    switch (position) {
      case 'top':
        return 'bottom-full mb-2';
      case 'bottom':
        return 'top-full mt-2';
      case 'left':
        return 'right-full mr-2';
      case 'right':
        return 'left-full ml-2';
      default:
        return 'bottom-full mb-2';
    }
  };

  return (
    <div 
      className={`relative inline-block ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {show && (
        <div 
          className={`absolute z-50 px-2 py-1 text-sm text-white bg-gray-900 rounded-md whitespace-nowrap ${getPositionClasses()}`}
          role="tooltip"
        >
          {content}
          <div 
            className={`absolute w-2 h-2 bg-gray-900 transform rotate-45 ${
              position === 'top' ? 'bottom-[-4px]' :
              position === 'bottom' ? 'top-[-4px]' :
              position === 'left' ? 'right-[-4px]' :
              'left-[-4px]'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;