import axios from 'axios';

const API_BASE_URL = 'https://project-scheduler-app.onrender.com/api';
const DEFAULT_TIMEOUT = 30000; // 30 seconds default timeout

// Define handleError function before using it
const handleError = (error) => {
  console.error('API error:', error);
  if (error.response) {
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
    
    // Only redirect if we're not already on the login page
    if (error.response.status === 401 && !window.location.pathname.includes('/login')) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    throw error.response.data.error || error;
  } else if (error.code === 'ECONNABORTED') {
    console.error('Request timed out:', error);
    throw new Error('The request took too long to complete. Please try again.');
  } else if (error.request) {
    console.error('No response received:', error.request);
    throw new Error('Unable to connect to the server. Please check your internet connection or try again later.');
  } else {
    console.error('Error setting up request:', error.message);
    throw error;
  }
};

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  // Remove manual Origin header setting
  console.log('Making request to:', config.baseURL + config.url);
  console.log('Request headers:', config.headers);
  return config;
}, (error) => {
  console.error('Request interceptor error:', error);
  return Promise.reject(error);
});

// Response interceptor for better error handling
api.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

const apiService = {
  login: (email, password, legacy = false) => {
    console.log('Login attempt for:', email, 'Legacy:', legacy);
    return api.post('/login', { email, password, legacy }).catch(handleError);
  },

  register: (userData) => {
    console.log('Registration attempt for:', userData.email);
    return api.post('/register', userData).catch(handleError);
  },

  confirmEmail: (email, confirmationCode) => {
    console.log('Email confirmation attempt for:', email);
    return api.post('/confirm-email', { email, confirmationCode }).catch(handleError);
  },

  getEmailDeliveryStatus: (projectId) => {
    console.log('Fetching email delivery status for project:', projectId);
    return api.get(`/projects/${projectId}/email-status`).catch(handleError);
  },

  resendProjectEmail: (trackerId) => {
    console.log('Resending email for tracker:', trackerId);
    return api.post(`/resend/${trackerId}`).catch(handleError);
  },

  getEmailTrackingMetrics: (projectId, startDate, endDate) => {
    console.log('Fetching email tracking metrics for project:', projectId);
    return api.get(`/metrics/${projectId}`, {
      params: { startDate, endDate }
    }).catch(handleError);
  },

  getBranch: (parentBranchId = null) => {
    // Don't make the request if we're on the login page
    if (window.location.pathname.includes('/login')) {
      return Promise.resolve({ data: [] });
    }
    console.log('Fetching branches', parentBranchId ? `for parent branch: ${parentBranchId}` : '(main branches)');
    return api.get('/branch', { params: { parentBranchId } }).catch(handleError);
  },

  createBranch: (branchData) => {
    console.log('Creating branch:', branchData);
    return api.post('/branch', branchData).catch(handleError);
  },

  updateBranch: (branchId, branchData) => {
    console.log('Updating branch:', branchId, branchData);
    return api.put(`/branch/${branchId}`, branchData).catch(handleError);
  },

  deleteBranch: (branchId) => {
    console.log('Deleting branch:', branchId);
    return api.delete(`/branch/${branchId}`).catch(handleError);
  },

  getProjects: (branchId) => {
    console.log('Fetching projects for branch:', branchId);
    return api.get('/projects', { params: { branchId } }).catch(handleError);
  },

  createProject: (projectData) => {
    console.log('Creating project:', projectData);
    return api.post('/projects', projectData, {
      timeout: DEFAULT_TIMEOUT * 2 // Double timeout for project creation
    }).catch(handleError);
  },

  updateProject: (id, projectData) => {
    console.log('Updating project:', id, projectData);
    return api.put(`/projects/${id}`, projectData, {
      timeout: DEFAULT_TIMEOUT * 2 // Double timeout for project updates
    }).catch(handleError);
  },

  deleteProject: (id) => {
    console.log('Deleting project:', id);
    return api.delete(`/projects/${id}`).catch(handleError);
  },

  getTechnicians: (branchId) => {
    console.log('Fetching technicians for branch:', branchId);
    return api.get('/technicians', { params: { branchId } }).catch(handleError);
  },

  createTechnician: (technicianData) => {
    console.log('Creating/Updating technician:', technicianData);
    return api.post('/technicians', technicianData).catch(handleError);
  },

  updateTechnician: (id, technicianData) => {
    console.log('Updating technician:', id, technicianData);
    return api.put(`/technicians/${id}`, technicianData).catch(handleError);
  },

  deleteTechnician: (id, branchId) => {
    console.log('Removing technician:', id, 'from branch:', branchId);
    return api.delete(`/technicians/${id}`, { params: { branchId } }).catch(handleError);
  },

  getContractor: (branchId) => {
    console.log('Fetching contractors for branch:', branchId);
    return api.get('/contractor', { params: { branchId } }).catch(handleError);
  },

  createContractor: (contractorData) => {
    console.log('Creating contractor:', contractorData);
    return api.post('/contractor', contractorData).catch(handleError);
  },

  updateContractor: (id, contractorData) => {
    console.log('Updating contractor:', id, contractorData);
    return api.put(`/contractor/${id}`, contractorData).catch(handleError);
  },

  deleteContractor: (id) => {
    console.log('Deleting contractor:', id);
    return api.delete(`/contractor/${id}`).catch(handleError);
  },

  getEmailSettings: (branchId) => {
    console.log('Fetching email settings for branch:', branchId);
    return api.get('/emailSettings', { params: { branchId } })
      .then(response => {
        if (response.data.data === null) {
          console.log('No email settings found for branch:', branchId);
          return { 
            data: {
              sendDailyReport: false,
              sendWeeklyReport: false,
              notifyNewProject: false,
              notifyProjectUpdate: false,
              technicianReminderDays: 1,
              reportRecipients: '',
              arEnabled: false,
              arRecipients: '',
              weeklyReportSchedule: { day: 'Friday', time: '17:00' },
            }
          };
        }
        return response;
      })
      .catch(handleError);
  },

  saveEmailSettings: (settingsData) => {
    console.log('Saving email settings:', settingsData);
    const formattedData = {
      ...settingsData,
      sendDailyReport: settingsData.sendDailyReport.toString(),
      sendWeeklyReport: settingsData.sendWeeklyReport.toString(),
      notifyNewProject: settingsData.notifyNewProject.toString(),
      notifyProjectUpdate: settingsData.notifyProjectUpdate.toString(),
      arEnabled: settingsData.arEnabled.toString(),
      technicianReminderDays: settingsData.technicianReminderDays.toString(),
      weeklyReportSchedule: JSON.stringify(settingsData.weeklyReportSchedule),
    };
    return api.post('/emailSettings', formattedData)
      .then(response => {
        console.log('Email settings saved successfully:', response.data);
        return response;
      })
      .catch(handleError);
  },

  getUsers: () => {
    console.log('Fetching users');
    return api.get('/users').catch(handleError);
  },

  deleteUser: (userId) => {
    console.log('Deleting user:', userId);
    return api.delete(`/users/${userId}`).catch(handleError);
  },

  sendWeeklySchedule: (branchId, subBranchId = null) => {
    console.log('Sending weekly schedule for branch:', branchId, 'sub-branch:', subBranchId);
    return api.post('/sendWeeklySchedule', { branchId, subBranchId }, {
      timeout: DEFAULT_TIMEOUT * 2 // Double timeout for sending schedules
    }).catch(handleError);
  },

  sendARSchedule: (branchId, subBranchId = null) => {
    console.log('Creating AR review request for branch:', branchId, 'sub-branch:', subBranchId);
    return api.post('/ar-review', { branchId, subBranchId }, {
      timeout: DEFAULT_TIMEOUT * 2 // Double timeout for AR review requests
    }).catch(handleError);
  },

  getARReview: (token) => {
    console.log('Fetching AR review data for token:', token);
    return api.get(`/ar-review/${token}`).catch(handleError);
  },

  submitARReview: (token, updates) => {
    console.log('Submitting AR review updates:', updates);
    return api.post(`/ar-review/${token}`, updates, {
      timeout: DEFAULT_TIMEOUT * 2 // Double timeout for AR review submissions
    }).catch(handleError);
  },

  getARHistory: (branchId, page = 1, limit = 10) => {
    console.log('Fetching AR review history for branch:', branchId);
    return api.get('/ar-review/history', {
      params: { branchId, page, limit }
    }).catch(handleError);
  },

  cancelARReview: (token) => {
    console.log('Cancelling AR review:', token);
    return api.post(`/ar-review/${token}/cancel`).catch(handleError);
  }
};

export default apiService;