import React, { useState, useEffect } from 'react';
import '../styles/EmailSettings.css';

function EmailSettings({ onClose, initialSettings, onSave, branchId }) {
  const [settings, setSettings] = useState({
    sendDailyReport: false,
    sendWeeklyReport: false,
    notifyNewProject: false,
    notifyProjectUpdate: false,
    technicianReminderDays: 3,
    weeklyReportSchedule: { day: 'Friday', time: '15:30' },
    reportRecipients: '',
    arEnabled: false,
    arRecipients: '',
    branchId: branchId,
    ...initialSettings
  });

  useEffect(() => {
    setSettings(prev => ({
      ...prev,
      sendDailyReport: !!prev.sendDailyReport,
      sendWeeklyReport: !!prev.sendWeeklyReport,
      notifyNewProject: !!prev.notifyNewProject,
      notifyProjectUpdate: !!prev.notifyProjectUpdate,
      arEnabled: !!prev.arEnabled
    }));
  }, [initialSettings]); // Add initialSettings as dependency

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(settings);
    // Don't close automatically - let the parent handle it
  };

  return (
    <div className="email-settings">
      <h2>Email Settings</h2>
      <form onSubmit={handleSubmit} className="email-settings-form">
        {/* Rest of the form remains the same */}
        <div className="form-group">
          <h3>Notifications</h3>
          <label>
            <input
              type="checkbox"
              name="sendDailyReport"
              checked={settings.sendDailyReport}
              onChange={handleChange}
            />
            Send Daily Report (at 8:00 AM)
          </label>
          <label>
            <input
              type="checkbox"
              name="sendWeeklyReport"
              checked={settings.sendWeeklyReport}
              onChange={handleChange}
            />
            Send Weekly Report (Friday at 3:30 PM)
          </label>
          <label>
            <input
              type="checkbox"
              name="notifyNewProject"
              checked={settings.notifyNewProject}
              onChange={handleChange}
            />
            Notify on New Project
          </label>
          <label>
            <input
              type="checkbox"
              name="notifyProjectUpdate"
              checked={settings.notifyProjectUpdate}
              onChange={handleChange}
            />
            Notify on Project Update
          </label>
        </div>

        <div className="form-group">
          <h3>AR Integration</h3>
          <label>
            <input
              type="checkbox"
              name="arEnabled"
              checked={settings.arEnabled}
              onChange={handleChange}
            />
            Enable AR Integration
          </label>
          <div className="ar-recipients">
            <input
              type="text"
              name="arRecipients"
              value={settings.arRecipients}
              onChange={handleChange}
              placeholder="AR Email Addresses (comma-separated)"
              disabled={!settings.arEnabled}
            />
          </div>
        </div>

        <div className="form-group">
          <h3>Technician Reminders</h3>
          <div className="reminder-setting">
            <label htmlFor="technicianReminderDays">Send reminder</label>
            <select
              id="technicianReminderDays"
              name="technicianReminderDays"
              value={settings.technicianReminderDays}
              onChange={handleChange}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(day => (
                <option key={day} value={day}>{day} day{day > 1 ? 's' : ''}</option>
              ))}
            </select>
            <span>before project</span>
          </div>
        </div>

        <div className="form-group">
          <h3>Report Recipients</h3>
          <input
            type="text"
            name="reportRecipients"
            value={settings.reportRecipients}
            onChange={handleChange}
            placeholder="Enter email addresses, separated by commas"
          />
        </div>

        <div className="form-actions">
          <button type="submit" className="btn-save">Save Settings</button>
          <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EmailSettings;