import React from 'react';
import { RefreshCw } from 'lucide-react';
import DeliveryStatus from './DeliveryStatus';

const EmailTrackingInfo = ({ 
  emailStatus, 
  onRefresh, 
  technicians = [], 
  contractors = [], 
  customerEmail 
}) => {
  const isLoading = !emailStatus;

  return (
    <div className="mt-4 space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">Email Delivery Status</h3>
        <button
          onClick={onRefresh}
          className="p-1 text-gray-500 hover:text-gray-700 transition-colors"
          disabled={isLoading}
        >
          <RefreshCw 
            size={20}
            className={`${isLoading ? 'animate-spin' : ''}`}
          />
        </button>
      </div>

      {isLoading ? (
        <div className="text-sm text-gray-500">Loading delivery status...</div>
      ) : (
        <div className="space-y-3">
          {/* Customer Email Status */}
          {customerEmail && (
            <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
              <div className="flex items-center">
                <span className="font-medium">Customer Email:</span>
                <span className="ml-2">{customerEmail}</span>
              </div>
              <DeliveryStatus 
                status={emailStatus.customer?.status}
                sentAt={emailStatus.customer?.sentAt}
                deliveredAt={emailStatus.customer?.deliveredAt}
              />
            </div>
          )}

          {/* Technicians Status */}
          {technicians.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium">Technicians</h4>
              {technicians.map(tech => (
                <div 
                  key={tech.id}
                  className="flex items-center justify-between p-2 bg-gray-50 rounded"
                >
                  <div className="flex items-center">
                    <span>{tech.name}</span>
                    <span className="ml-2 text-sm text-gray-500">({tech.email})</span>
                  </div>
                  <DeliveryStatus 
                    status={emailStatus.technicians[tech.id]?.status}
                    sentAt={emailStatus.technicians[tech.id]?.sentAt}
                    deliveredAt={emailStatus.technicians[tech.id]?.deliveredAt}
                  />
                </div>
              ))}
            </div>
          )}

          {/* Contractors Status */}
          {contractors.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium">Contractors</h4>
              {contractors.map(contractor => (
                <div 
                  key={contractor.id}
                  className="flex items-center justify-between p-2 bg-gray-50 rounded"
                >
                  <div className="flex items-center">
                    <span>{contractor.name}</span>
                    <span className="ml-2 text-sm text-gray-500">({contractor.email})</span>
                  </div>
                  <DeliveryStatus 
                    status={emailStatus.contractors[contractor.id]?.status}
                    sentAt={emailStatus.contractors[contractor.id]?.sentAt}
                    deliveredAt={emailStatus.contractors[contractor.id]?.deliveredAt}
                  />
                </div>
              ))}
            </div>
          )}

          {!customerEmail && technicians.length === 0 && contractors.length === 0 && (
            <div className="text-sm text-gray-500">
              No email recipients configured for this project.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmailTrackingInfo;