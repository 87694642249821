import React from 'react';
import '../styles/Header.css';
import logo from '../assets/mascott-logo.png';
import { useBranch } from '../BranchContext';

function Header({ 
  selectedDate,
  onDateChange,
  viewMode, 
  onViewModeChange 
}) {
  const { 
    branches, 
    selectedBranch, 
    setSelectedBranch, 
    selectedSubBranch, 
    setSelectedSubBranch 
  } = useBranch();

  const handleDateChange = (increment) => {
    const newDate = new Date(selectedDate);
    
    switch (viewMode) {
      case 'day':
        newDate.setDate(newDate.getDate() + increment);
        break;
      case 'week':
        newDate.setDate(newDate.getDate() + (increment * 7));
        break;
      default: // month view
        newDate.setMonth(newDate.getMonth() + increment);
    }
    
    onDateChange(newDate);
  };

  const handleBranchChange = async (e) => {
    const branchId = parseInt(e.target.value);
    if (!branchId) {
      setSelectedBranch(null);
      setSelectedSubBranch(null);
      return;
    }
    
    const branch = branches.find(b => b.id === branchId);
    if (branch) {
      await setSelectedBranch(branch);
      setSelectedSubBranch(null);
    }
  };

  const handleSubBranchChange = (e) => {
    const subBranchId = parseInt(e.target.value);
    if (subBranchId === 0) {
      setSelectedSubBranch(null);
    } else {
      const subBranch = selectedBranch.subBranches?.find(sb => sb.id === subBranchId);
      if (subBranch) {
        setSelectedSubBranch(subBranch);
      }
    }
  };

  const getDateDisplay = () => {
    switch (viewMode) {
      case 'day':
        return selectedDate.toLocaleDateString('en-US', { 
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });
      case 'week':
        const weekStart = new Date(selectedDate);
        weekStart.setDate(selectedDate.getDate() - selectedDate.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        return `${weekStart.toLocaleDateString('en-US', { 
          month: 'short',
          day: 'numeric'
        })} - ${weekEnd.toLocaleDateString('en-US', { 
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}`;
      default: // month view
        return selectedDate.toLocaleDateString('en-US', { 
          month: 'long',
          year: 'numeric'
        });
    }
  };

  const handleTodayClick = () => {
    onDateChange(new Date());
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="logo-container">
          <img src={logo} alt="Mascott Equipment Company" className="logo" />
        </div>
        <div className="header-controls">
          <div className="branch-selectors">
            <select 
              value={selectedBranch ? selectedBranch.id : ''}
              onChange={handleBranchChange}
              className="branch-select"
            >
              <option value="">Select a main branch</option>
              {branches.map(branch => (
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              ))}
            </select>
            
            {selectedBranch && selectedBranch.subBranches && selectedBranch.subBranches.length > 0 && (
              <select
                value={selectedSubBranch ? selectedSubBranch.id : 0}
                onChange={handleSubBranchChange}
                className="sub-branch-select"
              >
                <option value={0}>All Sub-branches</option>
                {selectedBranch.subBranches.map(subBranch => (
                  <option key={subBranch.id} value={subBranch.id}>{subBranch.name}</option>
                ))}
              </select>
            )}
          </div>
          
          <div className="date-controls">
            <button 
              onClick={handleTodayClick}
              className="today-button"
            >
              Today
            </button>
            <button 
              onClick={() => handleDateChange(-1)}
              className="nav-button prev-button"
            >
              &lt;
            </button>
            <span className="current-date">
              {getDateDisplay()}
            </span>
            <button 
              onClick={() => handleDateChange(1)}
              className="nav-button next-button"
            >
              &gt;
            </button>
          </div>
          
          <select 
            value={viewMode}
            onChange={(e) => onViewModeChange(e.target.value)}
            className="view-mode-select"
          >
            <option value="month">Month View</option>
            <option value="week">Week View</option>
            <option value="day">Day View</option>
          </select>
        </div>
      </div>
    </header>
  );
}

export default Header;