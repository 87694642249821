import React from 'react';
import { Check, Clock, AlertTriangle, Mail } from 'lucide-react';
import Tooltip from '../components/ui/tooltip'; // Updated import path

const DeliveryStatus = ({ status, sentAt, deliveredAt }) => {
  const getStatusDetails = () => {
    switch (status) {
      case 'DELIVERED':
        return {
          icon: <Check className="text-green-500" size={16} />,
          text: 'Delivered',
          tooltip: `Delivered on ${new Date(deliveredAt).toLocaleString()}`,
          className: 'bg-green-100'
        };
      case 'SENT':
        return {
          icon: <Clock className="text-yellow-500" size={16} />,
          text: 'Sent',
          tooltip: `Sent on ${new Date(sentAt).toLocaleString()}`,
          className: 'bg-yellow-100'
        };
      case 'FAILED':
        return {
          icon: <AlertTriangle className="text-red-500" size={16} />,
          text: 'Failed',
          tooltip: `Failed to deliver. Sent on ${new Date(sentAt).toLocaleString()}`,
          className: 'bg-red-100'
        };
      default:
        return {
          icon: <Mail className="text-gray-500" size={16} />,
          text: 'Not Sent',
          tooltip: 'Email not sent yet',
          className: 'bg-gray-100'
        };
    }
  };

  const details = getStatusDetails();

  return (
    <Tooltip
      content={details.tooltip}
      className="ml-2"
    >
      <div 
        className={`inline-flex items-center px-2 py-1 rounded-full ${details.className} gap-1`}
        role="status"
        aria-label={details.text}
      >
        {details.icon}
        <span className="text-xs font-medium">{details.text}</span>
      </div>
    </Tooltip>
  );
};

export default DeliveryStatus;