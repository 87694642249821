import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './authContext';
import apiService from './services/api.service';

const BranchContext = createContext();

export const useBranch = () => useContext(BranchContext);

export const BranchProvider = ({ children }) => {
  const { user } = useAuth();
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedSubBranch, setSelectedSubBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBranches = useCallback(async () => {
    if (window.location.pathname.includes('/login') || !user) {
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await apiService.getBranch();
      setBranches(response.data);
      
      // Only update selected branches if they exist in new data
      if (selectedBranch) {
        const updatedBranch = response.data.find(b => b.id === selectedBranch.id);
        if (!updatedBranch) {
          setSelectedBranch(null);
          setSelectedSubBranch(null);
        }
      }
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error fetching branches:', error);
        setError('Failed to fetch branches. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchBranches();
    } else {
      setBranches([]);
      setSelectedBranch(null);
      setSelectedSubBranch(null);
    }
  }, [user, fetchBranches]);

  const selectBranch = useCallback((branch) => {
    setSelectedBranch(branch);
    setSelectedSubBranch(null);
  }, []);

  const selectSubBranch = useCallback((subBranch) => {
    setSelectedSubBranch(subBranch);
  }, []);

  const refreshBranches = useCallback(async () => {
    if (user) {
      await fetchBranches();
    }
  }, [fetchBranches, user]);

  const addBranch = async (branchData) => {
    if (!user) return null;
    
    try {
      const response = await apiService.createBranch(branchData);
      await refreshBranches();
      return response.data;
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error adding branch:', error);
      }
      throw error;
    }
  };

  const addSubBranch = async (parentBranchId, subBranchData) => {
    if (!user) return null;
    
    try {
      const response = await apiService.createBranch({
        ...subBranchData,
        parentBranchId
      });
      await refreshBranches();
      return response.data;
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error adding sub-branch:', error);
      }
      throw error;
    }
  };

  const deleteBranch = async (branchId) => {
    if (!user) return;
    
    try {
      await apiService.deleteBranch(branchId);
      if (selectedBranch?.id === branchId) {
        setSelectedBranch(null);
        setSelectedSubBranch(null);
      } else if (selectedSubBranch?.id === branchId) {
        setSelectedSubBranch(null);
      }
      await refreshBranches();
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error deleting branch:', error);
      }
      throw error;
    }
  };

  const value = {
    branches,
    selectedBranch,
    setSelectedBranch: selectBranch,
    selectedSubBranch,
    setSelectedSubBranch: selectSubBranch,
    refreshBranches,
    addBranch,
    addSubBranch,
    deleteBranch,
    isLoading,
    error
  };

  return (
    <BranchContext.Provider value={value}>
      {children}
    </BranchContext.Provider>
  );
};

export default BranchContext;