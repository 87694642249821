import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import apiService from '../services/api.service';
import logo from '../assets/mascott-logo.png';
import '../styles/ConfirmEmail.css';

function ConfirmEmail() {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (!email) {
      setError('Email not found. Please try registering again.');
      setIsLoading(false);
      return;
    }

    try {
      await apiService.confirmEmail(email, confirmationCode);
      setIsConfirmed(true);
      setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to confirm email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isConfirmed) {
    return (
      <div className="confirm-email-page">
        <div className="confirm-email-container">
          <div className="logo-container">
            <img src={logo} alt="Mascott Equipment Company" className="logo" />
          </div>
          <div className="form-container">
            <h2>Email Confirmed!</h2>
            <p>Your email has been successfully verified. You will be redirected to the login page shortly.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="confirm-email-page">
      <div className="confirm-email-container">
        <div className="logo-container">
          <img src={logo} alt="Mascott Equipment Company" className="logo" />
        </div>
        <div className="form-container">
          <h2>Confirm Your Email</h2>
          {email ? (
            <>
              <p>Please enter the confirmation code sent to {email}.</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="confirmationCode">Confirmation Code</label>
                  <input
                    id="confirmationCode"
                    type="text"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    placeholder="Enter your confirmation code"
                    required
                    className="form-input"
                  />
                </div>
                <button type="submit" className="confirm-button" disabled={isLoading}>
                  {isLoading ? 'Confirming...' : 'Confirm Email'}
                </button>
              </form>
              {error && <p className="error-message">{error}</p>}
            </>
          ) : (
            <div>
              <p className="error-message">No email address found. Please try registering again.</p>
              <Link to="/register" className="register-link">Go to Registration</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;